<script>
import Loader from "./loader";
/**
 * Profile component
 */
export default {
  components: {
    Loader,
  },
  data() {
    return {
      displayName:"",
      email:"",
      job:"",
      userId:""
    };
  },
  mounted() {
    let users = JSON.parse(localStorage.getItem('user'))
    this.displayName = users.firstname +' '+ users.lastname
    this.job = users.companyName
    this.email = users.username
    this.userId = users.id
  },
  props: {
    updating: {
      type: Boolean,
    },
  },
};
</script>
<template>
  <Loader :loading="updating">
    <div class="card overflow-hidden">
      <div class="bg-soft bg-primary">
        <div class="row">
          <div class="col-7">
            <div class="text-primary p-3">
              <h5 class="text-primary">{{$t('user.DashboardsHome')}}</h5>
              <p>Şölen Portal</p>
            </div>
          </div>
          <div class="col-5 align-self-end">
            <img src="@/assets/images/profile-img.png" alt class="img-fluid" />
          </div>
        </div>
      </div>
      <div class="card-body pt-0">
        <div class="row">
          <div class="col-sm-12">
            <div class="avatar-md profile-user-wid mb-4">
              <div class="avatar-sm">                          
                <span class="avatar-title rounded-circle">{{displayName.charAt(0)}}</span>
              </div>
            </div>
            <h5 class="font-size-15 text-truncate">{{ displayName }}</h5>
            <p class="text-muted mb-0 text-truncate">{{ job }}</p>
          </div>

          <div class="col-sm-8">
            <div class="pt-4">
              <div class="mt-4">
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end card-body -->
    </div>
    <!-- end card -->
  </Loader>
</template>
